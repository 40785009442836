import "../assets/css/base.css";
import { BsFiletypeHtml, BsFiletypeCss, BsFiletypeScss } from "react-icons/bs";
import {
    BiLogoJavascript,
    BiLogoPython,
    BiLogoReact,
    BiLogoDjango,
    BiLogoRedux,
} from "react-icons/bi";
import { FaUbuntu, FaBootstrap, FaGitlab } from "react-icons/fa";
import { MdHttps } from "react-icons/md";
import {
    SiOvh,
    SiProxmox,
    SiDocker,
    SiNginx,
    SiOpenvpn,
    SiPfsense,
} from "react-icons/si";
import RealisationSer1 from "../components/homepage/realisationSer1";
import RealisationVelomad from "../components/homepage/realisationVelomad";
import RealisationCarnets from "../components/homepage/realisationCarnets";
import velomad from "../assets/illustrations/velomad/velomad.png";
import carnet from "../assets/illustrations/carnets/logo_les_carnets_du_chef.png";
import ser1 from "../assets/illustrations/ser1/logo__ma-maison-sereine.png";
import tresors from "../assets/img/lestresorsdelharmonie.jpg";
import ehonastea from "../assets/img/ehonastea.jpg";

export default function Homepage() {
    return (
        <main>
            <section className="bg-transparent">
                <h1>Développons votre potentiel, Ensemble !</h1>
                <div className="card">
                    <h2>Réalisons une solution métier qui vous ressemble.</h2>
                    <hr />
                    <p className="text-center">
                        Litiboost est une entreprise individuelle pouvant vous
                        aider dans vos développements informatiques : création
                        d'ERP en application web.
                    </p>
                    <p className="text-center">
                        Centraliser vos données et vos outils extérieurs grâce
                        aux API de connexions.
                    </p>
                </div>
            </section>{" "}
            <div class="custom-shape-divider-bottom-1706365476 reverse">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        class="shape-fill"
                    ></path>
                </svg>
            </div>
            <section className="home_techno">
                <h2>
                    Principales technologies utilisées dans les réalisations
                    clients
                </h2>

                <div className="ul-technlogy">
                    <div>
                        <div className="card-technology bg_language">
                            <h3>Langages Informatique : </h3>
                            <div>
                                <span className="col-techno">
                                    Javascript
                                    <BiLogoJavascript />
                                </span>
                                <span className="col-techno">
                                    Python <BiLogoPython />
                                </span>
                                <span className="col-techno">
                                    <BsFiletypeHtml /> - <BsFiletypeCss /> -{" "}
                                    <BsFiletypeScss />
                                </span>
                            </div>
                        </div>

                        <div className="card-technology bg_framework">
                            <h3>Frameworks : </h3>
                            <div>
                                <span className="col-techno">
                                    React <BiLogoReact />{" "}
                                </span>
                                <span className="col-techno">
                                    Redux <BiLogoRedux />{" "}
                                </span>{" "}
                                <span className="col-techno">
                                    Django <BiLogoDjango /> avec DjangoRestApi
                                </span>
                                <span className="col-techno">
                                    Bootstrap <FaBootstrap />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-technology bg_network">
                        <h3>Hébergement : </h3>
                        <div className="col-techno">
                            <span>Serveur dédié OVH avec :</span>
                            <SiOvh />
                        </div>

                        <div className="col-techno">
                            <span>Proxmox</span>
                            <SiProxmox />
                        </div>
                        <div className="col-techno">
                            <span>PfSense</span>
                            <SiPfsense />
                        </div>
                        <div className="col-techno">
                            <span>Ubuntu</span>
                            <FaUbuntu />
                        </div>
                        <div className="col-techno">
                            <span>Nginx</span>
                            <SiNginx />
                        </div>
                        <div className="col-techno">
                            <span>Certbot</span>
                            <MdHttps />
                        </div>
                        <div className="col-techno">
                            <span>OpenVpn</span>
                            <SiOpenvpn />
                        </div>

                        <div className="col-techno">
                            <span>
                                Self-hosted GitLab avec Pipeline CI/CD dont
                            </span>
                            <FaGitlab />
                        </div>
                        <div className="col-techno">
                            <span>Déploiements auto. des Dockers via SSH</span>
                            <SiDocker />
                        </div>
                    </div>
                </div>
            </section>{" "}
            <h2 className="title-confiance">Ils me font confiance : </h2>
            <section className="confiance">
                <div className="listing grid">
                    <div>
                        <img src={ser1} alt="Logo - Les Carnets du chef" />
                    </div>
                    <div>
                        <img
                            src={velomad}
                            alt="Logo - Velo Mad"
                            className="logo"
                        />
                    </div>
                    <div className="logo-carnet">
                        <img src={carnet} alt="Logo - Les Carnets du chef" />
                    </div>
                    <div className="logo-ehonastea">
                        <img src={ehonastea} alt="Ehonastea" />
                    </div>
                    <div className="logo-tresors">
                        <img src={tresors} alt="Les Trésors de l'Harmonie" />
                    </div>
                </div>
            </section>
            <div className="realisations">
                <h2>Denières Applications Web réalisées</h2>
                <RealisationVelomad />
                <RealisationSer1 />
                <RealisationCarnets />
            </div>
            <section className="contact">
                <p className="text-center">
                    Vous avez une idée de projet ? Vous souhaitez un devis ?
                </p>
                <hr />
                <p className="text-center">
                    N'hésitez pas à me contacter par mail à{" "}
                    <a href="mailto:contact@litiboost.fr">
                        contact@litiboost.fr
                    </a>{" "}
                    et je vous répondrais dans les plus brefs délais.
                </p>
            </section>
        </main>
    );
}
