import { useState } from "react";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from "reactstrap";
import carnetAccueil from "../../assets/illustrations/carnets/carnet-accueil.png";
import carnetAdminHome from "../../assets/illustrations/carnets/carnet-adminHome.png";
import carnetCarte from "../../assets/illustrations/carnets/carnet-carte.png";
import carnetPresentation from "../../assets/illustrations/carnets/carnet-creationPresentation.png";
import carnetEditRecipe from "../../assets/illustrations/carnets/carnets-editRecette.png";
import carnetGraphRecipe from "../../assets/illustrations/carnets/carnets-GraphiqueRecette.png";
import carnetPdfRecipe from "../../assets/illustrations/carnets/carnets-pdfCreation.png";

const items = [
    {
        src: carnetAccueil,
        altText: "Page d'accueil Jour/Nuit",
        caption: "Page d'accueil Jour/Nuit",
        key: 1,
    },
    {
        src: carnetAdminHome,
        altText: "Page Console de gestion",
        caption: "Page Console de gestion",
        key: 2,
    },
    {
        src: carnetCarte,
        altText: "Page des Créations à la Carte",
        caption: "Page des Créations à la Carte",
        key: 3,
    },
    {
        src: carnetPresentation,
        altText: "Page de présentation d'une Création",
        caption: "Page de présentation d'une Création",
        key: 4,
    },
    {
        src: carnetEditRecipe,
        altText: "Edition d'une Recette avec traduction GoogleTrad automatique",
        caption: "Edition d'une Recette avec traduction GoogleTrad automatique",
        key: 5,
    },
    {
        src: carnetGraphRecipe,
        altText: "Graphique de calcul Coût de Revient d'une Recette",
        caption: "Graphique de calcul Coût de Revient d'une Recette",
        key: 6,
    },
    {
        src: carnetPdfRecipe,
        altText: "Pdf généré d'une fiche technique d'une Création",
        caption: "Pdf généré d'une fiche technique d'une Création",
        key: 7,
    },
];

export default function CarrouselCarnets() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img
                    src={item.src}
                    alt={item.altText}
                    className="img-caroussel"
                />
                <CarouselCaption
                    className="bg-white text-secondary rounded-5 width-fit-content p-2 move-bottom-text "
                    captionHeader={item.altText}
                    captionText={""}
                />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            className="my-5"
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
}
