import CarrouselVelomad from "./carrouselVelomad";
import { FaShopify } from "react-icons/fa";
import velomad from "../../assets/illustrations/velomad/velomad.png";

export default function RealisationVelomad() {
    return (
        <div className="card_realisations">
            <div className="text-center mb-3">
                <img
                    src={velomad}
                    alt="Logo - Velo Mad"
                    className="logo_velo"
                />
            </div>

            <div className="text-center d-flex flex-column">
                <a href="https://maps.levelomad.com">
                    <small>https://maps.levelomad.com</small>{" "}
                </a>
                <a href="https://sav.levelomad.com">
                    <small>https://sav.levelomad.com</small>{" "}
                </a>
            </div>
            <hr />
            <div className="text-center">
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/DJANGO-REST-ff1709?style=for-the-badge&logo=django&logoColor=white&color=ff1709&labelColor=gray"
                    alt="made with Django REST"
                />{" "}
                <br />
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
                    alt="made with React"
                />
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/redux-%23593d88.svg?style=for-the-badge&logo=redux&logoColor=white"
                    alt="made with React redux"
                />
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/Graphs%20with-ReactRecharts-1f425f.svg"
                    alt="Graphs with React Recharts"
                />
            </div>
            <hr />
            <CarrouselVelomad />
            <hr />
            <ul>
                {" "}
                <li>
                    Réalisation d'une{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://maps.levelomad.com"
                    >
                        page répertoriant les partenaires du Velomad
                    </a>{" "}
                    du Velomad avec création base de données associées et
                    interface d'administration en interne.
                </li>
                <li>
                    Conception d'une{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://maps.levelomad.com"
                    >
                        page de demande de SAV
                    </a>{" "}
                    pour professionnels en B2B ainsi que les clients B2C avec
                    extractions de produits depuis les{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://shopify.dev/docs/api/admin-rest/"
                    >
                        API Shopify <FaShopify />{" "}
                    </a>
                    , création automatique d'un devis sur Shopify pour accélérer
                    leur traitement.
                </li>
                <li>
                    Réalisations de Tableaux de Statistiques sur les Commandes,
                    les Réparations, etc, depuis les{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://shopify.dev/docs/api/admin-rest/"
                    >
                        API Shopify <FaShopify />{" "}
                    </a>{" "}
                    avec Algorythmes de calculs spécifiquement conçus pour le
                    Velomad
                </li>
                <li>
                    Graphiques d'accompagnement avec{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://recharts.org/en-US"
                    >
                        ReactRecharts
                    </a>
                </li>
            </ul>
        </div>
    );
}
