import { useEffect } from "react";
import WidgetCGV from "../components/CGV.jsx";

export default function CGV() {
    document.title = "Litiboost | Conditions Générales de Ventes CGV";
    document.description = "Présentation des Conditions Générales de Ventes";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <WidgetCGV />;
}
