import { useState } from "react";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from "reactstrap";
import velomadSav from "../../assets/illustrations/velomad/velomad-sav-choixvelo.png";
import velomadSavPiece from "../../assets/illustrations/velomad/velomad-sav-choixpiece.jpg";
import velomadMaps from "../../assets/illustrations/velomad/velomad-maps.png";

import velomadEtatCommandes from "../../assets/illustrations/velomad/velomad-etatCommandes.png";
import velomadEtatReparations from "../../assets/illustrations/velomad/velomad_reparation.png";
import velomadEtatVelosVendus from "../../assets/illustrations/velomad/velomad-etatVelosVendus.png";

const items = [
    {
        src: velomadMaps,
        altText: "Page Partenaire",
        caption: "Slide 1",
        key: 1,
    },
    {
        src: velomadSav,
        altText: "Page SAV - Choix du vélo",
        caption: "#ApiShopify #React #DjangoREST",
        key: 2,
    },
    {
        src: velomadSavPiece,
        altText: "Page Sav - Choice Piece",
        caption: "Slide 2",
        key: 3,
    },
    {
        src: velomadEtatCommandes,
        altText: "Page Statut des commandes",
        caption: "Slide 2",
        key: 4,
    },
    {
        src: velomadEtatReparations,
        altText: "Page Stats des réparations",
        caption: "Slide 3",
        key: 5,
    },
    {
        src: velomadEtatVelosVendus,
        altText: "Page Algorythme Stats Vélos Vendus",
        caption: "Slide 3",
        key: 6,
    },
];

export default function CarrouselVelomad() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img
                    src={item.src}
                    alt={item.altText}
                    className="img-caroussel"
                />
                <CarouselCaption
                    className="bg-white text-secondary rounded-5 width-fit-content p-2 move-bottom-text "
                    captionHeader={item.altText}
                    captionText={""}
                />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            className="my-5"
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
}
