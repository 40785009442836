import logoCarnets from "../../assets/illustrations/carnets/logo_les_carnets_du_chef.png";
import CarrouselCarnets from "./carrouselCarnets";

export default function RealisationCarnets() {
    return (
        <div className="card_realisations">
            <div className="text-center mb-3">
                <img
                    src={logoCarnets}
                    alt="Logo - Les Carnets du Chef"
                    className="logo-carnets"
                />
            </div>

            <div className="text-center d-flex flex-column">
                <a href="https://lescarnetsduchef.fr">
                    <small>https://lescarnetsduchef.fr</small>{" "}
                </a>
            </div>
            <hr />
            <div className="text-center">
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/DJANGO-REST-ff1709?style=for-the-badge&logo=django&logoColor=white&color=ff1709&labelColor=gray"
                    alt="made with Django REST"
                />{" "}
                <br />
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
                    alt="made with React"
                />
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/redux-%23593d88.svg?style=for-the-badge&logo=redux&logoColor=white"
                    alt="made with React redux"
                />
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/Graphs%20with-ReactRecharts-1f425f.svg"
                    alt="Graphs with React Recharts"
                />
            </div>
            <hr />
            <CarrouselCarnets />
            <hr />
            <ul>
                <li>
                    Conception d'une Application web de Gestion de Créations
                    Culinaires (constituée de plusieurs recettes)
                </li>
                <li>
                    Détections des Allergènes et calculs du Coût de revient de
                    chaque recette de la Création
                </li>
                <li>
                    Export en PDF (Français et Anglais) de la Fiche Technique de
                    la Création, avec impression possible en format "ticket de
                    caisse"
                </li>
                <li>
                    Différents Grades d'utilisateurs : Administrateur,
                    Rédacteurs, Lecteurs
                </li>
                <li>Mode Jour/nuit et Mode Français/Anglais</li>
            </ul>
        </div>
    );
}
