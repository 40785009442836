import React from "react";
import ReactDOM from "react-dom/client";
import { configureStore } from "@reduxjs/toolkit";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";

import rootReducer from "./redux/reducers";
import Router from "./router/router.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = Router();
const store = configureStore({
    reducer: rootReducer,
    devTools: false,
});

Sentry.init({
    dsn: "https://a557dab76522dea93d89f8a7ec5f50bb@sentry.litiboost.fr/7",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/sentry\.litiboost\.fr\/api/,
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.7, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.7, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
root.render(
    <React.StrictMode>
        {" "}
        <Provider store={store}>
            <RouterProvider router={router} />{" "}
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
