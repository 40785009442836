export default function WidgetCGV() {
    return (
        <main className="p-5">
            <div>
                <h1 className="fs-1 text-center">
                    Conditions Générales de Ventes & Mentions Légales
                    <br />à compter du 30 Octobre 2023
                </h1>
                <p className="text-center fw-bold text-white">
                    Les présentes conditions sont soumises à la loi française.
                </p>

                <section
                    id="sommaire"
                    className="mx-auto col-11 col-md-9 col-lg-6  text-white"
                >
                    <h2 className="text-center fs-1">Sommaire</h2>
                    <ul>
                        <li>
                            <a href="#preambule">Préambule</a>
                        </li>
                        <li>
                            <a href="#art1">
                                Article 1 : Application des conditions générales
                                de vente.
                            </a>
                        </li>
                        <li>
                            <a href="#art2">Article 2 : Prestations</a>
                        </li>
                        <li>
                            <a href="#art3">Article 3 : Prestations et prix</a>
                        </li>
                        <li>
                            <a href="#art4">Article 4 : Devis</a>
                        </li>
                        <li>
                            <a href="#art5">Article 5 : Commande</a>
                        </li>
                        <li className="ms-3">
                            <a href="#art5.1">
                                Article 5.1 : Confirmation de la commande
                            </a>
                        </li>
                        <li className="ms-3">
                            <a href="#art5.2">
                                Article 5.2 : Acceptation de la prestation
                            </a>
                        </li>
                        <li className="ms-3">
                            <a href="#art5.2">
                                Article 5.3 : Modification de la prestation,
                                achat, et droit de rétractation.
                            </a>
                        </li>
                        <li>
                            <a href="#art6">Article 6 :Paiement</a>
                        </li>
                        <li className="ms-3">
                            <a href="#art6.1">
                                Article 6.1 : Modalités de paiement
                            </a>
                        </li>
                        <li className="ms-3">
                            <a href="#art6.2">
                                Article 6.2 : Retard et défaut de paiement
                            </a>
                        </li>
                        <li>
                            <a href="#art7">Article 7 : Livraison</a>
                        </li>
                        <li className="ms-3">
                            <a href="#art7.1">
                                Article 7.1 : Les délais et conditions de
                                livraison
                            </a>
                        </li>
                        <li className="ms-3">
                            <a href="#art7.2">
                                Article 7.2 : Les prestations et achats
                                commandés
                            </a>
                        </li>
                        <li>
                            <a href="#art8">Article 8 : Responsabilités</a>
                        </li>
                        <li>
                            <a href="#art9">
                                Article 9 : Garanties Généralités
                            </a>
                        </li>
                        <li>
                            <a href="#art10">
                                Article 10 : Clause de confidentialité
                            </a>
                        </li>
                        <li>
                            <a href="#art11">
                                Article 11 : Traitement des données personnelles
                            </a>
                        </li>
                        <li>
                            <a href="#art12">
                                Article 12 : Propriété intellectuelle & Droits à
                                l’image
                            </a>
                        </li>
                        <li>
                            <a href="#art13">
                                Article 13 : Protection des données
                            </a>
                        </li>
                        <li>
                            <a href="#art14">Article 14 : Cookies</a>
                        </li>
                        <li>
                            <a href="#art15">Article 15 : Modifications</a>
                        </li>
                        <li>
                            <a href="#art16">
                                Article 16 : Piratage et sécurité informatique
                            </a>
                        </li>
                        <li>
                            <a href="#art17">
                                Article 17 : Disponibilité de service - dans le
                                cadre d'un contrat d'hébergement
                            </a>
                        </li>
                        <li>
                            <a href="#art18">
                                Article 18 : Résiliation pour les contrats
                                d'hébergement
                            </a>
                        </li>
                        <li>
                            <a href="#art19">
                                Article 19 : Information précontractuelle
                            </a>
                        </li>
                        <li>
                            <a href="#art20">
                                Article 20 : Loi applicable au contrat{" "}
                            </a>
                        </li>
                        <li>
                            <a href="#art21">Article 21 : Litige</a>
                        </li>
                        <li>
                            <a href="#art22">
                                Article 22 : Coordonnées du conciliateur
                            </a>
                        </li>
                    </ul>
                </section>

                <div className="bg-primary hr-h w-50 mx-auto"></div>

                <section id="preambule">
                    <h2 className="fs-1">Préambule</h2>
                    <p>
                        L'entreprise individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » dont le siège social est
                        situé : 0 Chemin du Hequeux 44770 La Plaine sur Mer -
                        contact@litiboost.fr , immatriculées sous le numéro
                        Siret : N° de SIRET 978012235 00013 avec le N° de TVA
                        Intracommunautaire FR 20978012235 (facturation de la TVA
                        FR)
                    </p>
                    <p>
                        Monsieur TISSIER Lionel est propriétaire de la marque
                        “Litiboost” (marque déposée avec logo - présent en pied
                        de page) en date du 22/02/2021 sous le N°47 356 96
                        auprès de l’INPI en : classe 09 - Logiciel, application
                        web, application mobile classe 42 - Elaboration
                        (conception) de logiciel, développement de logiciels,
                        maintenance de logiciel classe 45 - Concession de
                        licence de logiciels. Propriétaire
                    </p>
                    <p></p>

                    <p>
                        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin
                        2004 pour la confiance dans l'économie numérique, il est
                        précisé aux utilisateurs du site internet
                        www.litiboost.fr l'identité des différents intervenants
                        dans le cadre de sa réalisation et de son suivi :
                        Responsable publication & Délégué à la protection des
                        données: Lionel TISSIER - contact@litiboost.fr - Le
                        responsable publication est une personne physique. Le
                        responsable publication est une personne physique.
                    </p>
                    <p>
                        Depuis le 07 Avril 2023, le présent site est hébergé sur
                        un serveur dédié est situé en France chez : <br />
                        OVH
                        <br />
                        SAS au capital de 10 174 560 €<br />
                        RCS Lille Métropole 424 761 419 00045
                        <br />
                        Code APE 2620Z
                        <br />
                        N° TVA : FR 22 424 761 419
                        <br />
                        Siège social : 2 rue Kellermann - 59100 Roubaix - France
                        <br />
                        OVH SAS est une filiale de la société OVH Groupe SA,
                        société immatriculée au RCS de Lille sous le numéro 537
                        407 926 sise 2, rue Kellermann, 59100 Roubaix.
                        <br />
                    </p>
                </section>

                <div className="bg-primary hr-h w-50 mx-auto"></div>

                <section id="art1">
                    <h2 className="fs-1">
                        Article 1: Application des conditions générales de
                        vente.
                    </h2>
                    <p>
                        Les présentes Conditions Générales de Vente sont
                        accessibles sur les sites internet : www.litiboost.fr et
                        peuvent être transmises à chaque client s’il en fait la
                        demande, à défaut un contrat lui a été transmis
                        reprennant tout ou partied des présentes conditions
                        générales de ventes suivantes.
                    </p>
                    <p>
                        En conséquence toute demande de services, demande de
                        devis, mise en place de contrat d'accompagnement,
                        contrat d'hébergement, qu’il soit un particulier ou une
                        entreprise emporte son adhésion, sans restriction, ni
                        réserve aux présentes Conditions Générales de Vente,
                        lesquelles prévalent sur tout autre document émis par
                        l’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost »
                    </p>
                    <p>
                        Toute autre condition imposée par le client sera
                        irrecevable aux conditions générales de vente sans la
                        signature d'un contrat.
                    </p>
                    <p>
                        Si l’exploitant individuel TISSIER Lionel exerçant sous
                        le nom commercial « Litiboost » ne respecte pas l’une
                        des quelconques règles présentes aux Conditions
                        Générales de Vente, cette tolérance ne peut être
                        interprétée comme valant renonciation aux dites
                        conditions.
                    </p>
                    <p>
                        Le client, qu’il soit un particulier ou une entreprise,
                        déclare avoir pris connaissance et accepté les présentes
                        Conditions Générales de Vente avant de passer toute
                        commande de services, produit, demande de devis, mise en
                        place de contrat d'accompagnement, contrat
                        d'hébergement. Les présentes Conditions Générales de
                        Vente s’appliquent jusqu’à ce qu’une nouvelle version
                        les remplace.
                    </p>
                </section>

                <section id="art2">
                    <h2 className="fs-1">Article 2 : Prestations</h2>
                    <p>
                        Les services proposés par l’exploitant individuel
                        TISSIER Lionel exerçant sous le nom commercial «
                        Litiboost » se produisent normalement du Mardi au Samedi
                        de 10h00 à 20h00. Néanmoins sur accord instauré par les
                        parties du ,l'entreprise peut offrir d’autres créneaux
                        de disponibilité surtout dans le cas d'une maintenance
                        informatique à réaliser en urgence suite à un bug génant
                        ou à une panne de serveur dans le cadre de la prestation
                        d'hébergement de serveur virtuel au sein du serveur
                        dédié Litiboost.
                    </p>
                </section>

                <section id="art3">
                    <h2 className="fs-1">Article 3 : Prestations et prix</h2>
                    <p>
                        Les prestations de services réalisées par Litiboost sont
                        réalisées en accord entre les deux parties et feront
                        l'objet d'une facturation mensuelle en fin de mois,
                        laissant au client un délai de règlement de J+30.
                    </p>
                    <p>
                        La tarification des prestations est issus d'un dévis
                        réalisées en amont ou si accord entre les deux parties
                        d'un tarification en lien avec la grille tarifiaire en
                        vigueur pouvant être demandée à tout moment par le
                        client et à été transmisses en même temps que la
                        fourniture d'un cahier des charges et /ou contrat.
                    </p>

                    <p>
                        Les prix communiqués par l’exploitant individuel TISSIER
                        Lionel au client sont toujours exprimés en HT - hors
                        taxe. Mais la facturation sera toujours réalisée en TTC
                        - toutes taxes comprises (intégration et facturation de
                        la TVA française).
                    </p>
                    <p>
                        L’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « litiboost » se réserve la possibilité
                        de modifier à tout moment ses tarifs.
                    </p>
                </section>
                <section id="art4">
                    <h2 className="fs-1">Article 4 : Devis</h2>
                    <p>
                        L’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » peut remettre gratuitement
                        un devis au client qui en fait la demande sous condition
                        de créneaux et de temps disponibles.
                    </p>
                </section>

                <section id="art5">
                    <h2 className="fs-1">Article 5 : Commande</h2>
                    <p>
                        Toute commande de prestations, de produits, ou de
                        demande de renseignement peut se faire par :
                    </p>
                    <ul>
                        <li>E-mail: contact@litiboost.fr</li>
                        <li>Téléphone : 06 21 82 59 15</li>
                        <li>
                            Courrier : <br />
                            Litiboost - TISSIER Lionel <br /> 0 Chemin du
                            Hequeux - Grand portail Noir
                            <br /> 44770 La Plaine sur Mer.
                        </li>
                    </ul>
                    <p>
                        L'entreprise individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » se réserve le droit de
                        refuser toute demande, commande de services qu’il
                        jugerait dangereuse, illicite ou contraire à l’ordre
                        public et aux bonnes mœurs de la société ou n'étant pas
                        adapter à ses compétences.
                    </p>
                    <p>
                        Le bénéfice de la prestation de service est strictement
                        personnel et réversé uniquement au client et ne peut
                        être transféré au bénéfice d’un tiers sans l’accord
                        préalable express de l’exploitant individuel TISSIER
                        Lionel exerçant sous le nom commercial « Litiboost ». Le
                        client s’engage à ne pas réaliser de sous-traitance,
                        sous-location ou de faire bénéficier du développement
                        réalisé par Litiboost à un tiers sans l’accord préalable
                        express de l’exploitant individuel TISSIER Lionel
                        exerçant sous le nom commercial « Litiboost ».
                    </p>
                    <p>
                        Pour toute commande, le client devra communiquer son
                        adresse personnelle, adresse électronique et numéro de
                        téléphone, N° de Siret si le client est un
                        professionnel.
                    </p>
                    <p>
                        L'entreprise individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » s’engage à répondre à la
                        demande du client dans les meilleurs délais, par
                        courrier électronique ou téléphone.
                    </p>
                </section>
                <section id="art5.1">
                    <h2 className="fs-1">
                        Article 5.1 : Confirmation de la commande
                    </h2>
                    <p>
                        L’acceptation de la commande du client par l’exploitant
                        individuel TISSIER Lionel exerçant sous le nom
                        commercial « Litiboost » s’effectuera par l’envoi d’un
                        devis ou d’un contrat.
                    </p>
                    <p>
                        Dans le cas d'une validation orale ou écrite sans
                        signature de contrat, les présentes conditions générales
                        de ventes s'appliqueront de manière automatiques et
                        déclencheront également l'émission d'une facturation en
                        fin de mois en cours au tarif en vigueur
                    </p>
                </section>
                <section id="art5.2">
                    <h2 className="fs-1">
                        Article 5.2 : Acceptation de la prestation
                    </h2>
                    <p>
                        Le client devra impérativement confirmer l’acceptation
                        de sa commande de prestation ou de produit en acceptant
                        le devis/contrat remis par courrier électronique.
                        L'entreprise individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » pourra demander un accompte
                        avant le démarrage de sa mission/prestations de services
                        et le client s’acquitter du règlement intégral de la
                        prestation, service, produit à la fin de la mission ou
                        du mois en cours dans le cadre d'un contrat à
                        facturation mensuelle et à tacite reconduction. Suite à
                        l’acceptation écrite du devis par le client, toute
                        prestation et achat de produit sera due.
                    </p>
                </section>
                <section id="art5.3">
                    <h2 className="fs-1">
                        Article 5.3 : Modification de la prestation, achat, et
                        droit de rétractation.
                    </h2>
                    <p>
                        Pour les particuliers : toute modification, annulation,
                        rétractation de commande faite par le client devra
                        intervenir au minimum 14 jours calendaires avant la date
                        de réalisation prévue, pour être prise en considération
                        par l’exploitant individuel TISSIER Lionel exerçant sous
                        le nom commercial « Litiboost ».
                    </p>
                    <p>
                        En cas d’exercice du droit de rétractation dans le délai
                        susvisé, sont remboursés : le prix de la prestation, le
                        remboursement sera effectué dans un délai de 14 jours à
                        réception de la demande de rétractation par courrier
                        recommandé.
                    </p>
                    <p>
                        Par conséquent, toute modification ou annulation de
                        commande par le client est soumise à l’acceptation de
                        l’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost », si le refus est exercé en
                        cours de mission, les sommes déjà versées par le client
                        ne lui seraient en aucun cas rendues.
                    </p>
                </section>

                <section id="art6">
                    <h2 className="fs-1">Article 6 : Le Paiement</h2>
                </section>
                <section id="art6.1">
                    <h2 className="fs-1">
                        Article 6.1 : Modalités de paiement
                    </h2>
                    <p>
                        Les prestations de services seront facturés sur la base
                        des tarifs précisément indiqués, en euros, en hors taxe,
                        sur le devis ou lors de la confirmation de la commande
                        par l’exploitant individuel TISSIER Lionel exerçant sous
                        le nom commercial « Litiboost ». Les prestations devront
                        être réglées intégralement, par virement bancaire dont
                        les coordonnées bancaire figurent sur la facture.
                        Concernant le délai, le client dispose de 30 jours à
                        compter de la date de facturation pour régler la
                        prestation.
                    </p>
                </section>
                <section id="art6.2">
                    <h2 className="fs-1">
                        Article 6.2 : Retard et défaut de paiement
                    </h2>
                    <p>
                        En cas de retard de paiement par le bénéficiaire, les
                        commandes / abonnement / contrat de maintenance /
                        contrat d'hébergement en cours pourront être
                        interrompues, sans mise en demeure préalable ni
                        préjudice de toute indemnité et/ou voie d’action.
                    </p>
                    <p>
                        Toute somme non payée à son échéance portera de plein
                        droit, et sans mise en demeure préalable, des pénalités
                        de retard avec l’application d’intérêts de retard au
                        taux légal.
                    </p>
                    <p>
                        Le défaut de paiement d’une facture à son échéance
                        autorise l’exploitant individuel TISSIER Lionel exerçant
                        sous le nom commercial « Litiboost » à annuler les
                        commandes en cours / abonnement / contrat de maintenance
                        / contrat d'hébergement , sans mise en demeure préalable
                        ni préjudice de toute indemnité et/ou voie d’action et
                        sans préjudice du recouvrement des sommes restant dues
                        par le bénéficiaire.
                    </p>
                </section>

                <section id="art7">
                    <h2 className="fs-1">Article 7 : La Livraison</h2>
                </section>
                <section id="art7.1">
                    <h2 className="fs-1">
                        Article 7.1 : Les délais et conditions de livraison
                    </h2>
                    <p>
                        Les délais de livraison et condition seront indiqués sur
                        le devis qui sera adressé au client par l’exploitant
                        individuel TISSIER Lionel exerçant sous le nom
                        commercial « Litiboost ». Si les délais de livraison ne
                        sont pas notifiés par le client (deadline), l'exploitant
                        individuel TISSIER Lionel exerçant sous le nom
                        commercial « Litiboost » essayera de réaliser la
                        prestation au plus rapide possible et ne pourra être
                        tenu responsable des dommages ou préjudices causés par
                        un retard lors de la livraison d’un service. Le
                        non-respect des délais de livraison convenus ne pourra
                        entraîner aucune indemnité de quelque nature.
                        l'exploitant
                    </p>
                    <p>
                        La réalisation n’est entamée qu’après confirmation du
                        paiement par l’organisme bancaire de l’exploitant
                        individuel TISSIER Lionel exerçant sous le nom
                        commercial « Litiboost ».
                    </p>
                </section>
                <section id="art7.2">
                    <h2 className="fs-1">
                        Article 7.2: Les prestations et achats commandés
                    </h2>
                    <p>
                        L’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » s'engage à mettre en œuvre
                        tous les moyens humains et matériels pour réaliser la
                        prestation de services et mise à disposition des
                        services demandés dans les délais annoncés lors de la
                        validation de commande. Mais il ne peut en aucun cas
                        être tenu responsable pour les retards de réalisation
                        occasionnés en raison de fautes qui ne lui sont
                        imputables (manque d'informations, données manquantes de
                        la partielle du client, etc). La prestation est fournie
                        à la date prévue sur le devis accepté par le client ou
                        si celle-ci n'est pas communiqués dans un délai
                        raisonnable de réalisation.
                    </p>
                    <p>
                        Si les prestations n'ont pas été effectuées dans le
                        délai prévu, la résolution de la vente pourra être
                        demandée par le client dans les conditions prévues aux
                        articles L216-2 et L216-3 du Code de la consommation.
                        Les sommes versées par le Client lui seront restituées
                        au plus tard dans les quatorze jours qui suivent la date
                        de dénonciation du contrat. Cette disposition ne
                        s'applique pas quand le retard de la société est dû à
                        une faute du client ou à un cas de force majeure, c'est
                        à dire à la survenance d'un évènement imprévisible,
                        irrésistible et indépendant de la volonté de
                        l’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost »
                    </p>
                </section>

                <section id="art8">
                    <h2 className="fs-1">Article 8 : Responsabilités </h2>
                    <p>
                        Dans la mesure où l’exploitant individuel TISSIER Lionel
                        exerçant sous le nom commercial « Litiboost » ne
                        jouerait qu'un rôle d'intermédiaire entre le client et
                        un fournisseur de services ou de biens tiers,
                        l’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » n’est tenu à aucune
                        obligation. Par conséquence, seule la responsabilité du
                        fournisseur pourra être engagée pour tout litige relatif
                        à l’exécution des prestations dont ce dernier a la
                        maîtrise. Le client s’adressera donc directement au
                        fournisseur du service ou produit concerné pour toute
                        demande (réparation, dédommagement, etc.).
                    </p>
                    <p>
                        L’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » est exonéré de toute
                        responsabilité concernant l’exécution du contrat de
                        vente, la livraison des produits et services commandés
                        et prestations exécutées par des prestataires
                        extérieurs.
                    </p>

                    <p>
                        L’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » ne saurait être tenu
                        responsable de l’inexécution de ses obligations en cas
                        de force majeure définie par le Code Civil, et notamment
                        en cas de grève totale ou partielle, inondation,
                        incendie, panne informatique extérieure, panne de
                        connexion internet indépendante de la bonne volonté de
                        Litiboost, panne et/ou saturations des réseaux mobiles
                        dans le cadre de la zone touristique où se trouve le
                        siège sociale de Litiboost et lieu d'exécution des
                        missions confiées, etc. (liste non exhaustive).
                    </p>
                    <p>
                        Litiboost dispose à ce jour d'une connexion internet
                        satelite pour mener à bien son travail mais restant
                        dépendant du prestataire lui fournissant ce service.
                    </p>
                </section>

                <section id="art9">
                    <h2 className="fs-1">Article 9 : Garanties Généralités </h2>
                    <p>
                        L’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » est garant de la conformité
                        de la prestation fornit dans le cadre d'un contrat,
                        permettant au client de formuler une demande de
                        modification ou de correction du service fournie si ceci
                        ne respecte pas le cahier des charges / contrat fournit
                        en amont. Ces Modifications seront gracieusement s'il
                        s'agit de petits correctifs de "bugs" ou l'object d'un
                        nouveau devis dans le cadre d'ajout de fonctionnalités
                        nouvelles ou de modification de la prestation initiale.
                    </p>
                </section>
                <section id="art10">
                    <h2 className="fs-1">
                        Article 10 : Clause de confidentialité
                    </h2>
                    <p>
                        Les présentes conditions générales de ventes impose une
                        discrétion concernant les données fournies par ses
                        clients (données clients, mot de passes d'accès à
                        certains services, codes accès à des clés API
                        extérieurs, etc). En effet, le développement des
                        fonctionnalités demandées par ses clients permet à
                        LiTiBoost d’avoir accès à des données sensibles et
                        confidentielles appartenant à ses clients (particuliers
                        ou professionnels).
                    </p>
                </section>

                <section id="art11">
                    <h2 className="fs-1">
                        Article 11 : Traitement des données personnelles
                    </h2>
                    <p>
                        Le présent site ne récolte aucune données personnelles
                    </p>
                    <p>
                        Dans le développement de ses services,l’exploitant
                        individuel TISSIER Lionel exerçant sous le nom
                        commercial « Litiboost » peut entraîner le traitement
                        des données à caractère personnel. Tout est mis en
                        oeuvre dans la mesure du possible pour limiter l'accès à
                        ses données qu'aux personnes/services ayant l'utilité de
                        ses données. Ces données ne sont pas revenues et restent
                        utilisées que pour les besoins de services développés et
                        demandés par les clients de litiboost. Si le client
                        refuse le traitement de ses données, il lui est demandé
                        de s'abstenir d’utiliser le service développé à sa
                        demande ou de demander de modifier le service développé
                        demandé. Ce traitement des données à caractère personnel
                        se fait dans le respect du Règlement Général sur la
                        Protection des Données 2016/679 du 27 avril 2016.
                    </p>
                    <p>
                        De plus, Litiboost recueille certaines informations de
                        la part de ses clients uniquement pour l'élaboration de
                        devis, de contrats, de factures pour ses clients. Par
                        ailleurs, conformément à la loi Informatique et Libertés
                        du 6 janvier 1978, le client dispose, à tout moment,
                        d'un droit d'interrogation, d'accès, de rectification,
                        de modification et d'opposition à l'ensemble de ses
                        données personnelles. En écrivant, par courrier et en
                        justifiant de son identité, à l'adresse suivante :
                        Litiboost - TISSIER Lionel - 0 Chemin du Hequeux - Grand
                        portail Noir - 44770 La Plaine sur Mer ou par email à
                        contact@litiboost.fr. Ces données personnelles sont
                        nécessaires au traitement de sa commande et à
                        l'établissement de ses factures le cas échéant.
                    </p>
                </section>
                <section id="art12">
                    <h2 className="fs-1">
                        Article 12 : Propriété intellectuelle & Droits à l’image
                    </h2>
                    <p>
                        Le logo de Litiboost et du présent site - litiboost.fr -
                        a été réalisé par M TISSIER Lionel et à fait l'objet
                        d'un dépôt de marque "Litiboost" auprès de l'INPI sous
                        le N° de certificat National 21 4 735 696 en dépôt du
                        22/02/2021. Cet enregistrement a été publié au Bulletin
                        officiel de la propriété industrielle N° 21/27 Vol. II
                        du 9 Juillet 2021.
                    </p>
                    <p>
                        Toute diffusion, exploitation, représentation,
                        reproduction, qu’elle soit partielle ou intégrale sans
                        l’autorisation expresse de litiboost exposera le
                        contrevenant à des poursuites civiles et pénales.
                    </p>
                    <p>
                        Les images utilisées sur le site sont libres de droits
                        et originaires du site pixabay.fr
                    </p>
                </section>
                <section id="art13">
                    <h2 className="fs-1">
                        Article 13 : Protection des données
                    </h2>
                    <p>
                        L’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » assure un niveau de
                        sécurité approprié et proportionnel aux risques encourus
                        ainsi qu'à leur probabilité, conformément au Règlement
                        Général sur la Protection des Données 2016/679 du 27
                        avril 2016. Toutefois, ces mesures ne constituent en
                        aucun cas une garantie et n'engagent pas l’exploitant
                        individuel TISSIER Lionel exerçant sous le nom
                        commercial « Litiboost » à une obligation de résultat
                        concernant la sécurité des données.
                    </p>
                    <p>
                        Des restrictions d'accès aux données ont été mises en
                        place lors des différents développements de services (
                        restrictions par adresse IP, VPN d'entreprise et d'accès
                        créé, différents pare-feu installés, restrictions de
                        ports appliqués, base de données réalisées pour les
                        besoins clients accessible que par VPN interne et ainsi
                        non accessible sur internet, seuls les personnes
                        autorisées peuvent consulter les données, etc)
                    </p>
                    <p>
                        LiTiBoost s’engage :
                        <ul>
                            <li>
                                - À assurer la sécurité des données du Client
                                contre les risques de divulgation, destruction,
                                corruption, piratage, détournement de ces
                                données par un tiers non habilité (même si un
                                piratage peut toujours arrivé, LiTiBoost ne
                                pourra pas être mise en demeure pour cela ainsi
                                pour les préjudice financiers pouvant avoir
                                lieu.);
                            </li>
                            <li>
                                - À ne pas exploiter pour ses besoins propres,
                                directement ou indirectement, ces données ; - À
                                ne pas céder ni mettre à disposition des tiers
                                les données et fichiers, sans l’accord préalable
                                et écrit du Client, à quelque fin que ce soit et
                                notamment à des fins commerciales ;
                            </li>
                            <li>
                                - À garantir la disponibilité, l’intégrité, la
                                confidentialité et la traçabilité des données et
                                à prendre les mesures adéquates pour qu’elles ne
                                soient pas compromises ;{" "}
                            </li>
                            <li>
                                - À restituer, au plus tard à la fin du Contrat,
                                une copie intégrale des données du Client (base
                                de données et fichiers multimédia fournit par le
                                client ou ses clients);
                            </li>
                        </ul>
                    </p>
                    <p>
                        Le Client doit, à tout moment, être en capacité de
                        localiser les données dont il est propriétaire. En
                        conséquence, LiTiBoost s’engage à ne pas transférer les
                        données recueillies vers un autre pays sans l’accord
                        préalable et écrit du Client; c’est pour cela que
                        LiTiBoost a fait le choix d’héberger l’intégralité de
                        ses services de serveur Cloud et de stockage S3 utilisés
                        chez OVH en France uniquement.
                    </p>
                    <p>
                        Le RGPD encadre le traitement des données personnelles
                        sur le territoire de l’Union européenne. Ainsi la base
                        de données a été constituée de manière à conserver
                        seules les données nécessaires à la bonne marche de
                        l’activité de ses clients. Ses clients doivent fournir à
                        LiTiBoost sa politique de RGPD (procédure de
                        conservation, sauvegarde, destruction de ses données
                        clients). D’après le RGPD : “par exemple, les données de
                        facturation doivent être conservées dix ans en
                        application du Code de commerce, même si la personne
                        concernée n’est plus cliente. Les données peuvent alors
                        être consultées de manière ponctuelle et motivée par des
                        personnes spécifiquement habilitées”
                        https://www.cnil.fr/sites/cnil/files/atoms/files/guide_durees_de_conservation.pdf
                        LiTiBoost ne pourra être tenu responsable d’un
                        non-respect du RGPD sans consigne écrite de la part de
                        ses clients.
                    </p>
                </section>

                <section id="art14">
                    <h2 className="fs-1">Article 14 : Cookies</h2>
                    <p>Le présent site ne comporte aucun cookie.</p>
                </section>
                <section id="art15">
                    <h2 className="fs-1">Article 15 : Modifications</h2>
                    <p>
                        L’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » se réserve le droit de
                        modifier le site, les services qui y sont proposés, les
                        CGV ainsi que toute procédure de livraison ou autre
                        élément constitutif des prestations réalisées par
                        l’exploitant individuel TISSIER Lionel exerçant sous le
                        nom commercial « Litiboost ». Lorsqu'il passe une
                        commande, l'utilisateur est soumis aux stipulations
                        énoncées par les CGV en vigueur lors de la passation de
                        la commande.
                    </p>
                </section>
                <section id="art16">
                    <h2 className="fs-1">
                        Article 16 : Piratage et sécurité informatique
                    </h2>
                    <p>
                        Comme indiqué dans le cahier des charges fournit à ses
                        clients et comme indiqué dans Article 13 : Protection
                        des données, les différents systèmes de serveur /
                        logiciels / procédés utilisés (Proxmox - pfSense - Pare
                        feu Iptable - Pare feu Ubuntu - Sécurité https -
                        Sécurité VPN - Contrôle CORS - etc. (liste non
                        exhaustive)) permettent de sécuriser les flux de
                        données.
                    </p>
                </section>

                <section id="art17">
                    <h2 className="fs-1">
                        Article 17 : Disponibilité de service - dans le cadre
                        d'un contrat d'hébergement
                    </h2>
                    <p>
                        LiTiBoost mettra tout en œuvre pour limiter au maximum
                        les ruptures de services pour ses clients. LiTiBoost ne
                        pourra pas être mise en demeure dans le cas d’une panne
                        (quelque nature que ce soit) ainsi que pour les
                        préjudices financiers pouvant avoir lieu pour le client.
                        LiTiBoost peut proposer, en option, un service de
                        redondance de serveur (serveur cloné sur deux serveurs
                        différent) pour limiter les ruptures de service
                        potentielles dû à une panne de serveur.
                    </p>
                    <p>
                        Le client est informé qu'il peut recourir à une
                        médiation conventionnelle, auprès de la Commission de la
                        médiation de la Consommation prévue à l'article L534-7
                        du Code de la consommation ou auprès des instances de
                        médiation sectorielles existantes. Il peut également
                        recourir à tout mode alternatif de règlement des
                        différends en cas de litige.
                    </p>
                </section>
                <section id="art18">
                    <h2 className="fs-1">
                        Article 18 : Résiliation pour les contrats d'hébergement
                    </h2>
                    <p>
                        Les contrats peut être résiliés dans un délai de trente
                        jours avant la fin d’une période trimestrielle par
                        lettre recommandée avec accusé de réception, afin
                        d’arrêter le contrat à la fin du trimestre en cours.
                    </p>
                    <p>
                        La résiliation entraînera un arrêt du serveur virtuel du
                        client automatiquement. Au Client de veiller à modifier
                        les DNS de son nom de domaine pour rediriger ses clients
                        vers son nouveau prestataire de service ou à demander la
                        résiliation de son nom de domaine à son prestataire. Le
                        Client obtiendra une copie de sa base de données ainsi
                        que de ses fichiers multimédias utilisés ou fournis par
                        ses propres clients.
                    </p>
                    <p>
                        Cependant, LiTiBoost reste propriétaire des serveurs
                        Frontend et Backend fournis au client et exploités par
                        le client. Le client a réglé au fur et à mesure du temps
                        les heures de travail de développement pour intégrer de
                        nouvelles fonctionnalités aux différents serveurs. Si le
                        client souhaite être en possession du code source, cela
                        devra faire l’objet d’un devis d’achat. Dans ce cas, le
                        client disposera de l’intégralité du code pour propulser
                        les différents serveurs, mais LiTiBoost pourra toujours
                        utiliser tout ou partie de fonctionnalités utilisées car
                        il n’y a pas de clause d’exclusivité dans le présent
                        contrat.
                    </p>
                </section>

                <section id="art19">
                    <h2 className="fs-1">
                        Article 19 : Information précontractuelle
                    </h2>
                    <p>
                        Préalablement à sa commande, le client reconnait avoir
                        eu communication, de façon lisible et compréhensible,
                        des CGV/CGU et des informations et renseignements prévus
                        aux articles L111-1 à L111-7 du Code de la consommation,
                        et notamment :
                    </p>
                    <ul>
                        <li>
                            Les caractéristiques essentielles des services
                            proposés
                        </li>
                        <li>Le prix des services</li>
                        <li>
                            La date ou le délai auquel Litiboost s'engage à
                            fournir le service, le cas échéant dans un délai
                            raisonnable de développement.
                        </li>
                        <li>
                            Les informations relatives à l'identité de Litiboost
                            et devant fournir les siennes égalements
                            (coordonnées postales, téléphoniques, électroniques)
                        </li>
                        <li>
                            Les informations relatives aux garanties légales et
                            contractuelles et à leurs modalités de mise en œuvre
                        </li>
                        <li>
                            La possibilité de recourir à une médiation
                            conventionnelle en cas de litige
                        </li>
                        <li>
                            Les informations relatives au droit de rétractation
                            (délai, modalités d'exercice).
                        </li>
                    </ul>
                </section>
                <section id="art20">
                    <h2 className="fs-1">
                        Article 20 : Loi applicable au contrat
                    </h2>
                    <p>
                        L’exploitant individuelTISSIER Lionel exerçant sous le
                        nom commercial « Litiboost » est une entreprise
                        individuelle de droit français. La loi applicable aux
                        relations contractuelles est la loi française
                        exclusivement ainsi que la facturation de la TVA (taxe
                        sur la valeur ajoutée) au taux français en vigueur
                    </p>
                </section>
                <section id="art21">
                    <h2 className="fs-1">Article 21 : Litige</h2>
                    <p>
                        Tout litige relatif à l’interprétation et à l’exécution
                        des conditions générales de services est soumis au droit
                        français. A défaut de résolution amiable, le litige sera
                        porté devant les tribunaux civils compétents.
                    </p>
                </section>
                <section id="art22">
                    <h2 className="fs-1">
                        Article 22 : Coordonnées du conciliateur
                    </h2>
                    <p>
                        Conciliateur du Ministère de la Justice Permanence sur
                        rendez-vous auprès de la Pornic Agglo Pays de Retz{" "}
                        <br />2 Rue du Dr Ange Guépin
                        <br />
                        44210 Pornic <br />
                        Téléphone : 02 51 74 07 16 <br />
                        Télécopie : 02 51 74 10 10
                    </p>
                </section>
            </div>
        </main>
    );
}
