import CarrouselSer1 from "./carrouselSer1";
import logoSer1 from "../../assets/illustrations/ser1/logo__ma-maison-sereine.png";
export default function RealisationSer1() {
    return (
        <div className="card_realisations">
            <div className="text-center mb-3">
                <img src={logoSer1} alt="Logo - Ma Maison Sereine" />
            </div>
            <div className="text-center">
                <a href="https://mamaisonsereine.fr">
                    <small>https://mamaisonsereine.fr</small>{" "}
                </a>
            </div>
            <hr />
            <div className="text-center">
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/DJANGO-REST-ff1709?style=for-the-badge&logo=django&logoColor=white&color=ff1709&labelColor=gray"
                    alt="made with Django REST"
                />{" "}
                <br />
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
                    alt="made with React"
                />
                <img
                    className="m-1"
                    src="https://img.shields.io/badge/redux-%23593d88.svg?style=for-the-badge&logo=redux&logoColor=white"
                    alt="made with React redux"
                />
            </div>
            <hr />
            <CarrouselSer1 />
            <hr />

            <ul>
                <li>
                    Création d'un site de présentation de locations de type
                    AirBnb
                </li>
                <li>
                    Possibilité pour les voyageurs (du site ou d'autres
                    plateforme de réservation) avec les{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://docs.smoobu.com/"
                    >
                        API Smoobu{" "}
                    </a>{" "}
                    de réserver des packs additionnels avec paiement en ligne
                    avec{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.stancer.com/documentation/fr/api/"
                    >
                        Strancer API{" "}
                    </a>
                </li>
                <li>
                    Connexion des voyageurs via envoi de Code SMS
                    d'authentification jetable via{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://github.com/ovh/python-ovh"
                    >
                        API OVH SMS
                    </a>
                </li>
                <li>
                    Intranet de gestion des réservations en interne avec
                    connexion aux{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://docs.smoobu.com/"
                    >
                        API Smoobu{" "}
                    </a>
                </li>
                <li>
                    Possibilité de recevoir les sms des voyageurs sur le site
                    ainsi que l'envoi de sms depuis le site via l'envoi d'une
                    impulsion à distance sur le portable du dirigeant avec ton
                    N° de téléphone professionnel pour un meilleur suivi client
                    et détection automatique de la réservation avec N° de
                    portable du voyageur.
                </li>
                <li>
                    Espace Employé pour la gestion des Ménages des différents
                    logements
                </li>

                <li>Site en version Française et Anglaise</li>
            </ul>
        </div>
    );
}
