const initialState = {};

export default function setupUser(state = initialState, action) {
    switch (action.type) {
        case "SET_THEME_COLOR":
            return {
                ...state,
                theme: action.theme,
            };

        default:
            return state;
    }
}
