import { useState } from "react";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from "reactstrap";
import homepage from "../../assets/illustrations/ser1/ser1-homepage.png";
import conciergerie from "../../assets/illustrations/ser1/ser1-conciergerie.png";
import listingReservations from "../../assets/illustrations/ser1/ser1-listingReservations.png";
import presentationLogement from "../../assets/illustrations/ser1/ser1-logement.png";

const items = [
    {
        src: homepage,
        altText: "Page d'accueil",
        caption: "#ApiShopify #React #DjangoREST",
        key: 1,
    },
    {
        src: conciergerie,
        altText: "Page de présentation Conciergerie",
        caption: "Slide 2",
        key: 2,
    },
    {
        src: presentationLogement,
        altText: "Page de présentation Logement",
        caption: "Slide 3",
        key: 3,
    },
    {
        src: listingReservations,
        altText: "Admin - Liste des réservations",
        caption: "Slide 3",
        key: 4,
    },
];

export default function CarrouselSer1() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img
                    src={item.src}
                    alt={item.altText}
                    className="img-caroussel"
                />
                <CarouselCaption
                    className="bg-white text-secondary rounded-5 width-fit-content p-2 move-bottom-text"
                    captionHeader={item.altText}
                />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            className="my-5"
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
}
