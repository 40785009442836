import "../../assets/css/base.css";
import "../../assets/css/header.css";

import chouette from "../../assets/img/chouette.png";

export default function HeaderNav() {
    return (
        <header>
            <div className="name_litiboost">
                <span>Litib</span>
                <img src={chouette} alt="Logo de LiTiBoost" />
                <span>st</span>
            </div>
            <nav></nav>
        </header>
    );
}
