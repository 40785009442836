import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import Homepage from "../pages/homepage";
import HeaderNav from "../components/header/header";
import Footer from "../components/footer/footer";
import CGV from "../pages/CGV";

export default function Router() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route>
                    <Route
                        index
                        path="/"
                        element={
                            <>
                                <HeaderNav />
                                <Homepage />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        index
                        path="/cgv-mentions-legales"
                        element={
                            <>
                                <CGV />
                                <Footer />
                            </>
                        }
                    ></Route>
                    <Route
                        path="*"
                        element={
                            <>
                                <HeaderNav />
                                <Homepage />
                                <Footer />
                            </>
                        }
                        status={404}
                    ></Route>
                </Route>
            </>
        )
    );
    return router;
}
