import { Link } from "react-router-dom";
import "../../assets/css/footer.css";

import chouette from "../../assets/img/chouette.png";

export default function Footer() {
    return (
        <footer>
            <div className="footer__left name_litiboost">
                <span>Litib</span>
                <img src={chouette} alt="Logo de LiTiBoost" />
                <span>st</span>
            </div>

            <div className="footer text-center">
                Entreprise Individuelle TISSIER Lionel <br />
                Marque Commerciale "Litiboost" <br />
                N° de SIRET 978012235 00013 <br /> TVA Intracommunautaire FR
                20978012235
            </div>
            <hr className="w-50 mx-auto" />
            <div className="footer text-center">
                <Link to="/cgv-mentions-legales">
                    Conditions Générales de Ventes & Mentions Légales
                </Link>
            </div>
        </footer>
    );
}
